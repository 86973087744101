<template>
	<div>
    <CToaster :autohide="3000">
      <template v-for="(toast, index) in toasts">
        <CToast
          :key="'toast' + index"
          :show="true"
          :header="toast.title"
        >
          {{toast.message}}
        </CToast>
      </template>
    </CToaster>
        <div style="display: flex; align-items:center; gap: 16px;">
		<h1 style="margin-bottom: 16px;">Horarios</h1>
            <CSelect
            style="width:150px;"
            :options="displayPeriodOptions"
            v-model="displayPeriod"
        />

        <CButton @click="showModal = true" style="margin-bottom: 16px;" color="primary">Añadir Horario</CButton>
        </div>
<!-- 		<calendar-view 
			:show-date="showDate"
            :items="appointments" 
            :display-period-uom="displayPeriod" 
            :display-period-count="1" 
            :starting-day-of-week="1"
			class="theme-default"
            @click-item="onClickEvent"
            >
			<calendar-view-header
				slot="header"
				slot-scope="t"
				:header-props="t.headerProps"
				@input="setShowDate">
            </calendar-view-header>
		</calendar-view> -->

<CModal title="Añadir Horario" color="primary" :show.sync="showModal"  class="modalReg">
        <div style=";margin: 0 auto !important;display: flex;flex-direction:column;align-items: center; gap: 20px;">
            <div>
                <label>Fecha:</label>
                <datetime type="date" v-model="newSchedule.date"></datetime>
            </div>
            <div>
                <label>Hora Inicio</label>
                <datetime type="time" v-model="newSchedule.start"></datetime>
            </div>
            <div>
                <label>Hora Termino</label>
                <datetime type="time" v-model="newSchedule.end"></datetime>
            </div>

            <CButton :disabled="!newSchedule.date || !newSchedule.start || !newSchedule.end" @click="addSchedule" color="primary">
                Añadir Horario
            </CButton>
        </div>
              <template #footer>
        <CButton @click="closeModal" color="secondary" size="lg">Cerrar</CButton>
      </template>
    </CModal>
	</div>
</template>
<script>
	import { CalendarView, CalendarViewHeader } from "vue-simple-calendar"
	require("vue-simple-calendar/static/css/default.css")
	require("vue-simple-calendar/static/css/holidays-us.css")
    import { createSpecialtySchedule } from '../../api/horarios'
    import moment from 'moment'

    
	export default {
		name: 'app',
		data: function() {
			return {
                showDate: new Date(),
                displayPeriodOptions: [{ value: 'month', label: 'Mes' }, { value: 'week', label: 'Semana' }],
                displayPeriod: 'month',
                showModal: false,
                schedules: [],
                newSchedule: {
                    date: new Date(),
                    start: '08:00',
                    end: '08:00',
                    specialty_id: 1
                },
                currentSchedule: {},
                toasts: [],
            }
		},
		components: {
			CalendarView,
			CalendarViewHeader,
		},
        methods: {
			setShowDate(d) {
				this.showDate = d;
			},
            onClickEvent(item) {
                this.getAppointmentData(item.id)
                this.showModal = true;
                this.currentItem = item;
            },
            addSchedule() {
                const data = {...this.newSchedule}
                data.date = moment(data.date).format("YYYY-MM-DD")
                data.start =  moment(data.start).format('HH:mm')
                data.end =  moment(data.end).format('HH:mm')

                createSpecialtySchedule(data).then(res => {
                    this.showModal = false;
                    this.toasts.push({
                        title: 'Horario Creado',
                    })
                }).catch(e => {
                    this.toasts.push({
                        title: 'Error al crear horario',
                    })
                })
            },
/*             getSchedule() {
                const data = {
                    date_start: moment(this.showDate).subtract(1, 'month').format("YYYY-MM-DD"),
                    date_end: moment(this.showDate).add(1, 'month').format("YYYY-MM-DD"),
                    specialty_id: 1,
                }
                getAttendingAppointments(data).then(res => {
                    this.appointments = res.appointments.map( appointment => {
                        return {
                            id: appointment.id,
                            startDate: new Date(appointment.start),
                            endDate: new Date(appointment.end),
                            title: `${appointment.attendee.name} - ${moment(appointment.start).format('HH:mm')}`
                        }
                    })
                })
            }, */
            closeModal() {
                this.showModal = false;
            },
		},
        filters: {
  FormatTime: function (value) {
    return moment(value).format('HH:mm')
  }
        },
/*         watch: {
            showDate: function(val){
                this.getAppointements();
            }
        },
        mounted() {
            this.getAppointements();
        }, */
	}
</script>
<style>
	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		color: #2c3e50;
		height: calc(100vh - 150px);
		width: 90vw;
		margin-left: auto;
		margin-right: auto;
	}
    .modalReg .modal-dialog {
        width: 20px;
        min-width: 400px;
    }
</style>